'use client'
import { HTMLAttributes, useState} from "react"
import "@general/styles/components/fly_text_block.scss"
export interface FlyTextBlockProps extends HTMLAttributes<HTMLDivElement> {
    animate: boolean
    disableAnimation: boolean
}
export function FlyTextBlock({ animate, disableAnimation, className, children }: FlyTextBlockProps): JSX.Element {
    const [wasAnimated, setWasAnimated] = useState(disableAnimation);
    if (animate && !wasAnimated) {
        setTimeout(() => {
            setWasAnimated(true)
        }, 4000)
    }
    let additionalClass = '';
    if (wasAnimated){
        additionalClass += ' was-animated';
    }else if(animate) {
        additionalClass += ' animate';
    }
    return <div className={className + ' fly_text_block' + additionalClass}>
    {children}
    </div>
        
}