'use client'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import '@general/styles/override/swiper/swiper.scss';
import '@general/styles/override/swiper/pagination.scss';
import "@general/styles/components/who_trust_us.scss"
import { useSelector } from 'react-redux';
import { RootState } from '@general/redux/store';
import { useTranslation } from '@general/i18n/client';
import { useIsSMOrLess } from '@general/services/hooks';
import osrImg from '@general/assets/osr.svg'
import growGuruImg from '@general/assets/grow_guru.webp'
import ofcImg from '@general/assets/ofk.webp'
import seedsMafiaImg from '@general/assets/seedsmafia.webp'
import planeteChocolatImg from '@general/assets/planetechocolat.webp'
import esdownloadImg from '@general/assets/esdownloadde.webp'
export function WhoTrust() {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'common')
    const forMobile = useIsSMOrLess();
    let WrapperTag = forMobile ? "div" : Swiper
    let wrapperProps: React.ComponentProps<typeof Swiper> = {}
    let ItemTag = forMobile ? "div" : SwiperSlide
    let itemExtraClass = ''
    if (forMobile) {
        wrapperProps.className = 'row'
        itemExtraClass = ' col-6'
    } else {
        wrapperProps.loop = true
        wrapperProps.slidesPerView = 4
        wrapperProps.autoplay = {
            delay: 8000,
            disableOnInteraction: true,
        }
        wrapperProps.pagination = {
            clickable: true
        }
        wrapperProps.modules = [Autoplay, Pagination]
    }
    return <div className='who-trust-us container pb-5'>
        <h3 className='title'>{t('Сlients worldwide')}</h3>
        <WrapperTag {...wrapperProps}>
            <ItemTag className={'osr client' + itemExtraClass}>
                <img src={osrImg.src}
                    alt={t('Online Star Register')}
                    loading="lazy"
                />
            </ItemTag>
            <ItemTag className={'planete-chocolat client' + itemExtraClass}>
                <img src={planeteChocolatImg.src}
                    alt={t('Planete chocolat')}
                    loading="lazy"
                />
            </ItemTag>
            <ItemTag className={'grow-guru client' + itemExtraClass}>
                <img src={growGuruImg.src}
                    alt={t('Grow Guru')}
                    loading="lazy"
                />
            </ItemTag>
            <ItemTag className={'ofc client' + itemExtraClass}>
                <img src={ofcImg.src}
                    alt={t('Офісклад')}
                    loading="lazy"
                />
            </ItemTag>
            <ItemTag className={'seeds-mafia client' + itemExtraClass}>
                <img src={seedsMafiaImg.src}
                    alt={t('Seeds mafia')}
                    loading="lazy"
                />
            </ItemTag>
            <ItemTag className={'esdownload client' + itemExtraClass}>
                <img src={esdownloadImg.src}
                    alt={t('Esdownload')}
                    loading="lazy"
                />
            </ItemTag>
        </WrapperTag>
    </div>
}