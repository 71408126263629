'use client'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation} from 'swiper/modules';
import '@general/styles/override/swiper/swiper.scss';
import '@general/styles/override/swiper/pagination.scss';
import '@general/styles/components/our_customers_are.scss'
import '@general/styles/override/swiper/navigation.scss';
import { RootState } from '@general/redux/store'
import { useSelector } from 'react-redux';
import { useTranslation } from '@general/i18n/client';

import startupImage from '@general/assets/our_clients_are/startup.webp'
import startupImageSm from '@general/assets/our_clients_are/startup_sm.webp'
import advImage from '@general/assets/our_clients_are/adv.webp'
import advImageSm from '@general/assets/our_clients_are/adv_sm.webp'
import apiImage from '@general/assets/our_clients_are/api.webp'
import apiImageSm from '@general/assets/our_clients_are/api_sm.webp'
import { FlyTextBlock } from '@general/components/client_side/animation/FlyTextBlock';
import { useIsSMOrLess } from '@general/services/hooks';

export function OurCustomersAre(): JSX.Element {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'common')
    const forMobile = useIsSMOrLess();
    const pagination = {
        clickable: true,
    };
    const extraProps = {
        navigation: true
    }
    if (forMobile) {
        extraProps.navigation = false
    }
    return <div className='our-customers-are'>
        <div className='container'>
            <h3 className='title'>{t('Our customers are')}</h3>
        </div>
        <Swiper
            loop={true}
            pagination={pagination}
            {...extraProps}
            modules={[Pagination, Navigation]}
        >
            <SwiperSlide>
                {({ isActive }) => {
                    return (<>
                        <img className='slider-background' src={forMobile ? startupImageSm.src : startupImage.src} alt={t('Small Startup')} />
                        <div className='slide-alpha-changel'>
                            <div className='context-block'>
                                <FlyTextBlock animate={isActive} disableAnimation={false} className='container text-white'>
                                    <h2 className='h2'>{t('Small Startups')}</h2>
                                    <p className='h5'>{t(`Small businesses looking to kickstart their ventures on existing platforms, but need customized solutions without breaking the bank on labor costs. 
                                        They know exactly what they need and are on the hunt for the right people to get the job done.`)}
                                    </p>
                                    <p className='h5'>{t(`For e-commerce, we offer solutions based on the PrestaShop and Magento platforms, allowing for the creation of fully scalable solutions.`)}
                                    </p>
                                    <p className='h5'>{t(`For physical stores and restaurants, we provide solutions based on the Odoo platform.`)}
                                    </p>
                                    <p className='h5'>{t(`We implement landing pages using the React and Next.js frameworks.`)}
                                    </p>
                                    
                                </FlyTextBlock>
                            </div>
                        </div>
                    </>
                    )
                }}
            </SwiperSlide>
            <SwiperSlide>
                {({ isActive }) => {
                    return (<>
                        <img className='slider-background' src={forMobile ? apiImageSm.src : apiImage.src} alt={t('Customization Required')} />
                        <div className='slide-alpha-changel'>
                            <div className='context-block'>
                                <FlyTextBlock animate={isActive} disableAnimation={false} className='container text-white'>
                                    <h2 className='h2'>{t('Customization Required')}</h2>
                                    <p className='h5'>{t(`Existing companies looking to expand the capabilities of their projects on platforms such as PrestaShop, Odoo, Magento, and others, by integrating with various services that provide access to their APIs.`)}
                                    </p>
                                    <p className='h5'>{t(`Companies looking to expand the functionality of existing projects through code customization or the development of new extensions.`)}
                                    </p>
                                    <p className='h5'>{t(`Customers in need of identifying and resolving errors in existing projects.`)}
                                    </p>                                    
                                </FlyTextBlock>
                            </div>
                        </div>
                    </>
                    )
                }}
            </SwiperSlide>
            <SwiperSlide>
                {({ isActive }) => {
                    return (<>
                        <img className='slider-background' src={forMobile ? advImageSm.src : advImage.src} alt={t('For Adv Campaign Setup')} />
                        <div className='slide-alpha-changel'>
                            <div className='context-block'>
                                <FlyTextBlock animate={isActive} disableAnimation={false} className='container text-white'>
                                    <h2 className='h2'>{t('For Adv Campaign Setup')}</h2>
                                    <p className='h5'>{t(`Specifically, clients in need of setting up campaigns from scratch in AdWords, Facebook, and teaser networks.`)}
                                    </p>
                                    <p className='h5'>{t(`Additionally, clients dissatisfied with the results of already set up campaigns in AdWords and Facebook.`)}
                                    </p>
                                    <p className='h5'>{t(`Customers seeking to track the effectiveness of each individual campaign in AdWords and Facebook.`)}
                                    </p>                                    
                                </FlyTextBlock>
                            </div>
                        </div>
                    </>
                    )
                }}
            </SwiperSlide>
        </Swiper>
    </div>
    
}