'use client'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import '@general/styles/override/swiper/swiper.scss';
import '@general/styles/override/swiper/pagination.scss';
import '@general/styles/override/swiper/navigation.scss';
import '@general/styles/components/main_promo_slider.scss'
import { RootState } from '@general/redux/store'
import { useSelector } from 'react-redux';
import { useTranslation } from '@general/i18n/client';

import slideImage1 from '@general/assets/main_promo_slide_1.webp'
import slideImage1SM from '@general/assets/main_promo_slide_1_sm.webp'
import slideImage2 from '@general/assets/main_promo_slide_2.webp'
import slideImage2SM from '@general/assets/main_promo_slide_2_sm.webp'
import slideImage3 from '@general/assets/main_promo_slide_3.webp'
import slideImage3SM from '@general/assets/main_promo_slide_3_sm.webp'
import slideImage4 from '@general/assets/main_promo_slide_4.webp'
import slideImage4SM from '@general/assets/main_promo_slide_4_sm.webp'
import { FlyTextBlock } from '@general/components/client_side/animation/FlyTextBlock';
import { useIsSMOrLess } from '@general/services/hooks';
import { ContactFormModal } from '@general/components/client_side/ContactFormModal';
import { useState } from 'react';

export function MainPromoSlider(): JSX.Element {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'common')
    const forMobile = useIsSMOrLess();
    const [modalContactFormShow, setModalContactFormShow] = useState(false);
    const pagination = {
        clickable: true,
    };
    const extraProps = {
        navigation: true
    }
    if (forMobile) {
        extraProps.navigation = false
    }
    return <>
        <ContactFormModal
            show={modalContactFormShow}
            onHide={() => setModalContactFormShow(false)}
            place_from="main_promo_slider"
        />
        <Swiper
            className='main-promo-slider'
            loop={true}
            pagination={pagination}
            {...extraProps}
            autoplay={{
                delay: 8000,
                disableOnInteraction: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
        >
            <SwiperSlide>
                {({ isActive }) => {
                    return (<>
                        <img className='slider-background' src={forMobile ? slideImage1SM.src : slideImage1.src} alt={t('WM Web Agency')} />
                        <div className='slide-alpha-changel'>
                            <div className='context-block'>
                                <FlyTextBlock animate={isActive} disableAnimation={forMobile} className='container text-white'>
                                    <h2 className='h2'>{t('We are')} {t('WM Web Agency')}</h2>
                                    <h3 className='h3'>{t('Since 2012 do web projects of varying complexity:')}</h3>
                                    <p className='h5'><i className='icon-marketplace-alt'></i> - {t('E-commerce based on: prestashop, magento, odoo')}</p>
                                    <p className='h5'><i className='icon-model-cube-arrows'></i> - {t('3d and 2d web sites on webgl and canvas')}</p>
                                    <p className='h5'><i className='icon-data-transfer'></i> - {t('ERP integration to e-commerce')}</p>
                                    <button className='btn btn-dark' onClick={() => setModalContactFormShow(true)}>{t('Grow with us')} <i className='icon-chat-arrow-grow'></i></button>
                                </FlyTextBlock>
                            </div>
                        </div>
                    </>
                    )
                }}
            </SwiperSlide>
            <SwiperSlide>
                {({ isActive }) => {
                    return (
                        <>
                            <img className='slider-background' src={forMobile ? slideImage2SM.src : slideImage2.src} alt={t('Legacy code')} loading="lazy" />
                            <div className='slide-alpha-changel'>
                                <div className='context-block'>
                                    <FlyTextBlock animate={isActive} disableAnimation={false} className='container text-white'>
                                        <h2 className='h2'>{t('Legacy code')}</h2>
                                        <h3 className='h3'>{t('We can take care of any of your old code if it satisfies these needs:')}</h3>
                                        <p className='h5'><i className='icon-keyboard'></i> - {t('Written in the following languages: JavaScript, Python, PHP, Golang, Java')}</p>
                                        <p className='h5'><i className='icon-book'></i> - {t('You can explain the business logic if we ask about any part of your app')}</p>
                                        <p className='h5'><i className='icon-lock-open-alt'></i> - {t('Code opened and license allow modifications by external persons')}</p>
                                        <button className='btn btn-dark' onClick={() => setModalContactFormShow(true)}>{t('Need support?')} <i className='icon-onboarding'></i></button>
                                    </FlyTextBlock>
                                </div>
                            </div>
                        </>

                    )
                }}
            </SwiperSlide>
            <SwiperSlide>
                {({ isActive }) => {
                    return (
                        <>
                            <img className='slider-background' src={forMobile ? slideImage3SM.src : slideImage3.src} alt={t('Legacy code')} loading="lazy" />
                            <div className='slide-alpha-changel'>
                                <div className='context-block'>
                                    <FlyTextBlock animate={isActive} disableAnimation={false} className='container text-white'>
                                        <h2 className='h2'>{t('Linux Server Solutions')}</h2>
                                        <h3 className='h3'>{t('Get in touch today for expert Linux server maintenance services!')}</h3>
                                        <p className='h5'><i className='icon-it-computer'></i> - {t('Streamline Linux server setup, configuration, and management')}</p>
                                        <p className='h5'><i className='icon-dashboard'></i> - {t('Ensure optimal performance and reliability with proactive maintenance')}</p>
                                        <p className='h5'><i className='icon-shield-check'></i> - {t('Safeguard your infrastructure with robust security measures')}</p>
                                        <button className='btn btn-dark' onClick={() => setModalContactFormShow(true)}>{t('Need admin?')} <i className='icon-admin-alt'></i></button>
                                    </FlyTextBlock>
                                </div>
                            </div>
                        </>

                    )
                }}
            </SwiperSlide>
            <SwiperSlide>
                {({ isActive }) => {
                    return (
                        <>
                            <img className='slider-background' src={forMobile ? slideImage4SM.src : slideImage4.src} alt={t('Legacy code')} loading="lazy" />
                            <div className='slide-alpha-changel'>
                                <div className='context-block'>
                                    <FlyTextBlock animate={isActive} disableAnimation={false} className='container text-white'>
                                        <h2 className='h2'>{t('Boost Your Online Presence')}</h2>
                                        <h3 className='h3'>{t('Enhance visibility with:')}</h3>
                                        <p className='h5'><i className='icon-addwords'></i> - {t('Expert AdWords management')}</p>
                                        <p className='h5'><i className='icon-url'></i> - {t('Targeted SEO strategies')}</p>
                                        <p className='h5'><i className='icon-users'></i> - {t('Engaging social media campaigns')}</p>
                                        <p className='h5'><i className='icon-message-sms'></i> - {t('Personalized messenger marketing')}</p>
                                        <button className='btn btn-dark' onClick={() => setModalContactFormShow(true)}>{t('Promote?')} <i className='icon-megaphone'></i></button>
                                    </FlyTextBlock>
                                </div>
                            </div>
                        </>

                    )
                }}
            </SwiperSlide>
        </Swiper>
    </>
}