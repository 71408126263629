import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/home/wmweb/nextjs/components/client_side/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/home/wmweb/nextjs/components/client_side/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MainPromoSlider"] */ "/home/wmweb/nextjs/components/client_side/home/MainPromoSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurCustomersAre"] */ "/home/wmweb/nextjs/components/client_side/home/OurCustomersAre.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhoTrust"] */ "/home/wmweb/nextjs/components/client_side/home/WhoTrust.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhyWe"] */ "/home/wmweb/nextjs/components/client_side/home/WhyWe.tsx");
