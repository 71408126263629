'use client'
import { ContactForm } from "@general/components/client_side/ContactForm";
import '@general/styles/components/footer.scss'
export function Footer({title,description}: {title:string, description: string}): JSX.Element {
    return <div className='footer py-5'>
        <div className='container'>
            <h3 className="h1 text-center text-white text-uppercase">{title}</h3>
            <p className="h4 text-center text-white pb-5">{description}</p>
            <ContactForm />
        </div>
    </div>
}