'use client'
import { useTranslation } from "@general/i18n/client"
import { RootState } from "@general/redux/store"
import "@general/styles/components/why_we.scss"
import { useSelector } from "react-redux"

export function WhyWe(): JSX.Element{
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'why_we')
    return <div className="container why-we">
        <div className="row">
            <div className="col-md-3 col-sm-6 info-block">
                <i className='icon-users-alt'></i>
                <div className="title">
                    {t('Small team, big impact')}
                </div>
                <div className="description">
                    {t('Unlock the power for tailored solutions, agile development, rapid innovation, all backed by personalized support')}
                </div>
            </div>
            <div className="col-md-3 col-sm-6 info-block">
                <i className='icon-shield-check'></i>
                <div className="title">
                    {t('If we commit, we deliver')}
                </div>
                <div className="description">
                    {t('Choose us, and rest assured that if we take on your project, we\'ll deliver exceptional results every step of the way')}
                </div>
                
            </div>
            <div className="col-md-3 col-sm-6 info-block">
                <i className='icon-phone-call'></i>
                <div className="title">
                    {t('Always within reach')}
                </div>
                <div className="description">
                    {t('Your dev team, just a call or message away for seamless support and accelerated success')}
                </div>
            </div>
            <div className="col-md-3 col-sm-6 info-block">
                <i className='icon-team'></i>
                <div className="title">
                    {t('Smooth integration')}
                </div>
                <div className="description">
                    {t('We seamlessly integrate with your existing team for enhanced collaboration and accelerated project success')}
                </div>
            </div>
        </div>
    </div>
}